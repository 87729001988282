<template>
  <div class="card p-2">
    <b-row class="mb-2">
      <b-col col lg="6">
        <h4 v-if="propostaEdicao"> Editar de Proposta: {{ dadosItem.codigo_proposta }} </h4>
        <h4 v-else> Cadastro de Proposta </h4>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Tipo de Proposta" label-for="tipo_proposta">
          <div class="d-flex mb-2">
            <b-form-radio v-model="dadosItem.tipo_proposta" class="mr-2" name="tipo_proposta-radios" value="LEAD"
              :disabled="propostaEdicao">
              LEAD
            </b-form-radio>
            <b-form-radio v-model="dadosItem.tipo_proposta" name="tipo_proposta-radios" value="CLIENTE"
              :disabled="propostaEdicao">
              CLIENTE
            </b-form-radio>
          </div>

        </b-form-group>
      </b-col>
      <b-col cols="6">

        <b-form-group :label="dadosItem.tipo_proposta === 'CLIENTE' ? 'Cliente' : 'Lead'" label-for="tipoCliente"
          v-if="dadosItem.tipo_proposta">
          <select-com-pesquisa :itens-para-edicao="[dadosItem.cliente]" v-if="dadosItem.tipo_proposta === 'CLIENTE'"
            placeholder="Escolha um cliente" url="/clientes" :multiple="false" :disabled="propostaEdicao"
            @selecionados="tratarEventoClienteSelecionado" />
          <select-com-pesquisa :itens-para-edicao="[dadosItem.lead]" v-if="dadosItem.tipo_proposta === 'LEAD'"
            placeholder="Escolha um lead" url="/leads" :multiple="false" :disabled="propostaEdicao"
            @selecionados="tratarEventoLeadSelecionado" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Data Solicitação" label-for="data_solicitacao">

          <b-form-input type="date" prepend="Data Solicitação" v-model="formattedDataSolicitacao"
            placeholder="Data Solicitação" />


        </b-form-group>
      </b-col>

    </b-row>
    <b-row>

      <b-col cols="3">
        <b-form-group label="Data Entrega" label-for="data_entrega">
          <b-form-input type="date" prepend="Data Entrega" v-model="formattedDataEntrega" placeholder="Data Entrega" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Empresa" label-for="empresa">
          <select-com-pesquisa :itens-para-edicao="[dadosItem.empresa]" placeholder="Escolha uma empresa"
            url="/empresas" :multiple="false" :disabled="false" @selecionados="tratarEventoEmpresaSelecionada" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Responsável" label-for="responsavel">
          <select-com-pesquisa :itens-para-edicao="[dadosItem.responsavel]" placeholder="Escolha um responsável"
            url="/users?role=Líder_Comercial&role=Coordenação_Comercial" :multiple="false" :disabled="false"
            @selecionados="tratarEventoResponsavelSelecionado" />
        </b-form-group>
      </b-col>


      <b-col cols="4">
        <b-form-group label="Solicitante" label-for="solicitante">

          <b-form-input v-model="dadosItem.solicitante" placeholder="Informe o solicitante" type="text" />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Possui Parceiro" label-for="existe_parceiro">
          <div class="d-flex">
     
            <b-form-radio v-model="dadosItem.existe_parceiro" class="mr-2" name="some-radios" :value="true">
              SIM
            </b-form-radio>
            <b-form-radio v-model="dadosItem.existe_parceiro" class="mr-2" name="some-radios" :value="false">
              NÃO
            </b-form-radio>
          </div>

        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Escolha um parceiro" label-for="parceiro" v-if="dadosItem.existe_parceiro">
          <select-com-pesquisa :itens-para-edicao="[dadosItem.parceiro]" placeholder="Escolha um parceiro"
            url="/clientes" :multiple="false" :disabled="false" @selecionados="tratarEventoParceiroSelecionado" />

        </b-form-group>
      </b-col>
      <b-col cols="12">

        <b-form-group label="Descrição" label-for="descricao">
          <quill-editor-pensou :item-edicao="dadosItem.descricao" @input="tratarDescricao" toolbar-id="toolbar" />

        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Tipo de Custos" label-for="tipo_custo">

          <div class="d-flex mb-2">
            <b-form-radio v-model="dadosItem.tipo_custo" class="mr-2" name="tipo_custo-radios" value="BIM">
              BIM
            </b-form-radio>
            <b-form-radio v-model="dadosItem.tipo_custo" name="tipo_custo-radios" value="CAD">
              CAD
            </b-form-radio>
          </div>

        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="Nome Proposta" label-for="nome">
          <b-form-input v-model="dadosItem.nome" placeholder="Informe o nome da proposta" type="text" />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Status" label-for="status">
          <select-com-pesquisa :itens-para-edicao="[dadosItem.status]" placeholder="Escolha um status"
            url="/status?modulo=propostas&setor=comercial" :multiple="false" :disabled="false" select-first-option
            @selecionados="tratarEventoStatusSelecionado" />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-between">
      <div>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-scrollable variant="outline-primary">
          Adicionar Subdisciplinas
        </b-button>

      </div>

      <div class="d-flex" v-if="listaSubs">

        <div class="font-weight-bold mr-1 d-flex align-items-center">
        <div> Valor da Proposta:</div> 
           </div>
        <div class="font-weight-bold d-flex flex-column align-items-center">
          <div
            :class="{ 'text-decoration-line-through font-medium-4': valorTotalDesconto < valorTotalTeste && valorTotalDesconto > 0 }">

            {{
              formatarParaMoeda(valorTotalTeste)
            }}
          </div>
          <div class="font-large-1 text-primary" v-if="valorTotalDesconto > 0"> {{
            formatarParaMoeda(valorTotalTeste - valorTotalDesconto)
            }}
          </div>
        </div>

      </div>
    </div>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-form-group label-for="subdisciplinas">
          <b-list-group>
            <div v-for="item in listaSubs" :key="item.displina_nome">
              <b-list-group-item active>

                <div class="d-flex justify-content-between">
                  <div>
                    {{ item.disciplina_nome }}
                    <b-badge variant="dark" pill class="badge-round" v-if="item.subdisciplinas.length > 0"> {{
                      item.subdisciplinas.length }}
                    </b-badge>
                  </div>

                  <div class="d-flex">
                    <b-button v-b-tooltip.hover.top="'Aplicar desconto na disciplina'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-light"
                      class="btn-icon rounded-circle mr-2" @click="abrirModalDesconto(item)">
                      <feather-icon icon="DollarSignIcon" />
                    </b-button>

                    <div class="d-flex flex-column">
                      <div :class="{ 'text-decoration-line-through font-small-2': item.desconto > 0 }"> Total :{{
                        formatarParaMoeda(item.total) }}</div>
                      <div v-if="item.desconto > 0" class="font-weight-bold"> Total: {{
                        formatarParaMoeda(item.total - item.desconto)
                        }}</div>

                    </div>
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item v-for="sub in item.subdisciplinas" :key="sub.uuid">

                <div class="d-flex justify-content-between ">
                  <div class="font-size-7 d-flex align-items-center">
                    {{ sub.nome }}
                  </div>
                  <div class="d-flex font-size-8">

                    <v-select style="width: 100px" :options="sub.unidades_medida" :clearable="false" label="abreviacao"
                      placeholder="U.M." :no-options="'Nenhuma opção disponível'"
                      @input="updateUnidadeMedida(sub, $event)" v-model="sub.unidades_medida">
                      <template #no-options="{ search, searching, loading }">
                        Nenhuma opção cadastrada para a subdisciplina
                      </template>
                    </v-select>
                    <b-form-input class="ml-2" style="width: 100px" size="sm" v-model="sub.quantidade" type="number"
                      min="1" />
                    <b-form-input class="ml-2" style="width: 120px" size="sm" v-model="sub.valor" v-money="{
                      decimal: ',',
                      thousands: '.',
                      prefix: 'R$ ',
                      precision: 2,
                      masked: false,
                      allowBlank: true,
                      minimumNumber: 0
                    }" />
                    <div class="font-weight-bold ml-2 text-right" style="width:100px;"> {{ calculoTotalSub(sub) }}
                    </div>
                
                    <b-button variant="danger" size="sm" class="ml-2" @click="removerSub(sub)">
                      <feather-icon icon="TrashIcon" size="16" />
                    </b-button>
                  </div>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2 d-flex justify-content-end">
        <b-button variant="outline-dark" :to="{ name: 'propostas' }">
          Cancelar
        </b-button>
        <b-button variant="success" class="ml-1" @click="salvarProposta()">
          Salvar
        </b-button>

      </b-col>
    </b-row>
    <b-modal id="modal-desconto" title="Escolha o tipo de desconto" ok-title="Aplicar" ok-variant="success"
      cancel-title="Cancelar" cancel-variant="light" @ok="salvarDesconto()">
      <b-form-group label="Tipo de Desconto">
        <b-form-radio-group v-model="tipoDesconto" name="tipo-desconto">
          <b-form-radio value="REAIS">Reais</b-form-radio>
          <b-form-radio value="PERCENTUAL">Percentual</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="tipoDesconto === 'REAIS'" label="Valor do Desconto em reais">

        <b-form-input v-model="valorDescontoReais" v-money="{
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false,
          allowBlank: true,
          minimumNumber: 0
        }" placeholder="Digite o valor em reais" />

      </b-form-group>

      <b-form-group v-else label="Valor do desconto em %">
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="outline-secondary">
              <feather-icon icon="PercentIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input v-model="valorDescontoPercentual" v-mask="'###%'" placeholder="Digite o valor percentual" />
        </b-input-group>
      </b-form-group>
    </b-modal>
    <b-modal id="modal-scrollable" scrollable centered size="lg" title="Adicionar subdisciplinas a proposta"
      cancel-title="Fechar" ok-title="Adicionar" @ok="adicionarSubdisciplinas" @cancel="subsFiltradasModal = []"
      ok-variant="success" cancel-variant="outline-secondary">
      <div class="modal-body">
        <b-row>
          <b-col cols="12">
            <select-com-pesquisa :itensParaEdicao="[]" placeholder="Escolha a Disciplina" url="/disciplinas"
              :multiple="false" @selecionados="tratarEventoDisciplinasSelecionadas" />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center py-3" v-if="loadModal">
          <b-spinner variant="primary" label="Carregando subdisciplinas..." />
        </div>
        <b-row v-if="subsFiltradasModal.length">
          <b-col cols="12" class="mt-2">
            <h6 class="text-uppercase">Escolha as subdisciplinas</h6>
            <b-form-checkbox-group id="checkbox-group-subs" v-model="subsSelecionadasModal" name="subsSelecionadas"
              v-if="dadosItem.tipo_custo === 'BIM'">
              <b-form-checkbox class="d-block" v-for="sub in subsFiltradasModal" :key="sub.uuid"
                :value="{ uuid: sub.uuid, tipo_custo: 'BIM', unidades_medida: sub.unidades_medida, unidade_medida: sub.unidade_medida, nome: sub.nome, valor: sub.valor_bim, disciplina: sub.disciplina.name, disciplina_id: sub.disciplina.id, quantidade: 1 }">
                <span class="nomeItemCheck"> {{ sub.nome }}</span>
              </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-checkbox-group id="checkbox-group-subs" v-model="subsSelecionadasModal" name="subsSelecionadas"
              v-else>
              <b-form-checkbox class="d-block" v-for="sub in subsFiltradasModal" :key="sub.uuid"
                :value="{ uuid: sub.uuid, tipo_custo: 'CAD', nome: sub.nome, valor: sub.valor_cad, disciplina: sub.disciplina.name, disciplina_id: sub.disciplina.id, quantidade: 1 }">
                <span class="nomeItemCheck"> {{ sub.nome }}</span>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>

  </div>
</template>
<script>
  import {
    BBadge,
    BButton,
    BCardText,
    BCol,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BModal,
    BRow,
    BSpinner,
    VBModal,
    VBTooltip
  } from 'bootstrap-vue'
  import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
  import store from '@/store'
  import propostaStoreModule from '@/views/apps/propostas/propostaStoreModule'
  import Ripple from 'vue-ripple-directive'
  import axios from '@/libs/axios'
  import QuillEditorPensou from '@/views/forms/form-element/quill-editor/QuillEditorPensou.vue'
  import useItemList from '@/views/apps/propostas/list/useItemList'
  import vSelect from 'vue-select'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import { onUnmounted, ref } from '@vue/composition-api'
  import { formatarParaMoeda, limpaMoeda, percentualParaReal, formatarDataAmericano, formatarData } from '@core/utils/filter'
  import { quillEditor } from 'vue-quill-editor'

  import { useToast } from 'vue-toastification/composition'
  import { useRouter } from '@core/utils/utils'

  export default {
    name: 'CreateProposta',
    components: {
      QuillEditorPensou,
      BButton,
      BFormRadio,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      SelectComPesquisa,
      BFormCheckboxGroup,
      BListGroupItem,
      BCardText,
      BModal,
      BFormCheckbox,
      BListGroup,
      BBadge,
      BSpinner,
      BFormSpinbutton,
      quillEditor,
      vSelect,
      BFormRadioGroup,
      BInputGroup,
      BInputGroupPrepend,

    },
    directives: {
      'b-modal': VBModal,
      'b-tooltip': VBTooltip,
      Ripple,
    },

    setup(props) {
      const ITEM_APP_STORE_MODULE_NAME = 'propostas'
      const propostaEdicao = ref(false)
      const toast = useToast()

      const {
        route,
        router
      } = useRouter()

      // Register module
      if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, propostaStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
      })

      if (route.value?.params.id) {
        propostaEdicao.value = true
      }

      const {
        create,
        update,
        getItem
      } = useItemList()

      return {
        toast,
        create,
        update,
        getItem,
        formatarParaMoeda,
        formatarDataAmericano,
        propostaEdicao
      }
    },
    async mounted() {
      if (this.propostaEdicao) {
        await this.carregarDadosEdicao()
      }
    },
    data() {
      return {
        disciplinaParaAplicarDesconto: '',
        valorTotalComDesconto: 0,
        editorOption: {
          modules: {
            toolbar: '#quill-toolbar',
          },
          placeholder: 'Informe uma descrição',
        },
        tipoDesconto: 'REAIS',
        valorDescontoReais: '',
        valorDescontoPercentual: '',
        subsFiltradasModal: [],
        subsSelecionadasModal: [],
        loadModal: false,
        listaSubs: [],
        valorTotalTeste: 0,
        valorTotalDesconto: 0,
        dadosItem: {
          codigo_proposta: null,
          data_solicitacao: '',
          existe_parceiro: false,
          tipo_proposta: 'CLIENTE',
          tipo_custo: 'BIM',
          data_entrega: '',
          cliente: null,
          status: null,
          lead: null,
          empresa: null,
          responsavel: null,
          parceiro: null,
          descricao: '',
          solicitante: null
        }
      }
    },
    computed: {
      formattedDataSolicitacao: {
        get() {
          return this.formatarDataAmericano(this.dadosItem.data_solicitacao);
        },
        set(value) {
          this.dadosItem.data_solicitacao = value;
        }
      },
      formattedDataEntrega: {
        get() {
          return this.formatarDataAmericano(this.dadosItem.data_entrega);
        },
        set(value) {
          this.dadosItem.data_entrega = value;
        }
      },   
    },
    watch: {
      'dadosItem.tipo_custo': {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.listaSubs = []
          }
        },
        deep: true
      },   
      tipoDesconto(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.valorDescontoReais = ''
          this.valorDescontoPercentual = ''
        }
      },
      'listaSubs': {
        handler(newValue, oldValue) {
          if (oldValue.length > 0) {
            newValue.forEach(item => {
              item.total = this.calculoValorDisciplinaTeste(item.subdisciplinas);
              this.valorTotalTeste = this.calculoValorPropostaTeste(newValue);
              this.valorTotalDesconto = this.calculoValorDescontoTeste(newValue);
            });
          }

        },
        deep: true,
      },
    },
    methods: {
      async carregarDadosEdicao() {
        this.dadosItem = await this.getItem(this.$route.params.id)
        this.dadosItem.tipo_proposta = this.dadosItem.cliente ? 'CLIENTE' : 'LEAD'
        this.listaSubs = this.dadosItem.itens

      },
      abrirModalDesconto(item) {
        this.disciplinaParaAplicarDesconto = item
        this.valorDescontoPercentual = ''
        this.valorDescontoReais = ''
        this.$bvModal.show('modal-desconto')
      },
      adicionarSubdisciplinas() {
        this.subsFiltradasModal = [];

        const disciplinaId = this.subsSelecionadasModal[0].disciplina_id;
        const disciplinaNome = this.subsSelecionadasModal[0].disciplina;

        const disciplinaJaAdicionada = this.listaSubs.find(item => item.disciplina_id === disciplinaId);

        let novasSubs = [];
        let totalSubs = 0;

        this.subsSelecionadasModal.forEach(item => {
          totalSubs += limpaMoeda(item.valor) * item.quantidade;
          novasSubs.push(item);
        });

        if (disciplinaJaAdicionada) {
          // Adicionar novas subdisciplinas à disciplina existente

          novasSubs.forEach(novaSub => {
            const subJaExiste = disciplinaJaAdicionada.subdisciplinas.some(sub => sub.uuid === novaSub.uuid);
            if (!subJaExiste) {
              disciplinaJaAdicionada.subdisciplinas.push(novaSub);
              disciplinaJaAdicionada.total += limpaMoeda(novaSub.valor) * novaSub.quantidade;
              disciplinaJaAdicionada.desconto = 0;
            }
          });
        } else {
          // Adicionar nova disciplina com subdisciplinas
          let novoItem = {
            disciplina_id: disciplinaId,
            disciplina_nome: disciplinaNome,
            desconto: 0,
            subdisciplinas: [...novasSubs],
            total: totalSubs
          };
          this.listaSubs.push(novoItem);
        }

      },
      calculoValorDescontoTeste(disciplinas) {
        return disciplinas.reduce((acc, disc) => {
          return acc + disc.desconto
        }, 0)
      },
      calculoValorDisciplinaTeste(subs) {
        return subs.reduce((acc, sub) => {
          return acc + limpaMoeda(sub.valor) * sub.quantidade
        }, 0)
      },
      calculoValorPropostaTeste(disciplinas) {
        return disciplinas.reduce((acc, disc) => {
          return acc + disc.total
        }, 0)
      },

      calculoTotalSub(sub) {
        return formatarParaMoeda(limpaMoeda(sub.valor) * sub.quantidade)
      },
      removerSub(item) {
     
        const disciplinaId = item.disciplina_id;
        const subId = item.uuid;

        // Encontrar a disciplina pelo disciplina_id
        const disciplina = this.listaSubs.find(item => item.disciplina_id === disciplinaId);
        if (disciplina) {

          // Filtrar a subdisciplina pelo uuid
          disciplina.subdisciplinas = disciplina.subdisciplinas.filter(sub => sub.uuid !== subId);
          // Atualizar o total da disciplina
          disciplina.total = disciplina.subdisciplinas.reduce((acc, sub) => acc + limpaMoeda(sub.valor) * sub.quantidade, 0);
          // Atualizar o desconto para zero
          disciplina.desconto = 0;
          // Remover a disciplina se não houver mais subdisciplinas
          if (disciplina.subdisciplinas.length === 0) {
            this.listaSubs = this.listaSubs.filter(item => item.disciplina_id !== disciplinaId);
          } else {
            // Atualizar a referência da disciplina no array para garantir reatividade
            this.listaSubs = [...this.listaSubs];
          }
          if(this.listaSubs.length === 0){
            this.valorTotalTeste = 0
            this.valorTotalDesconto = 0
          }
        }

      },

      salvarDesconto() {
        const desconto = this.valorDescontoReais ? limpaMoeda(this.valorDescontoReais) : percentualParaReal(this.disciplinaParaAplicarDesconto.total, this.valorDescontoPercentual)

        if (desconto > this.disciplinaParaAplicarDesconto.total) {
          this.toast.error('Desconto não pode ser maior que o valor total da proposta.')
          return
        }
        const disciplinaId = this.disciplinaParaAplicarDesconto.disciplina_id;

        // Encontrar a disciplina pelo disciplina_id
        const disciplina = this.listaSubs.find(item => item.disciplina_id === disciplinaId);
        if (disciplina) {
          // Atualizar o desconto da disciplina
          disciplina.desconto = desconto;
          // Atualizar a referência da disciplina no array para garantir reatividade
          this.listaSubs = [...this.listaSubs];
        }

        this.$bvModal.hide('modal-desconto');

      },

      salvarProposta() {
        const context = this

        let itens = this.listaSubs
        if (itens.length === 0) {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Adicione pelo menos uma subdisciplina para salvar a proposta.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          return
        }

        const total = itens.reduce((acc, item) => {
          return acc + item.total
        }, 0)

        const desconto = itens.reduce((acc, item) => {
          return acc + item.desconto
        }, 0)

        // Verificar se alguma subdisciplina tem unidades_medida inexistente ou vazia

        for (const disciplina of itens) {
          for (const sub of disciplina.subdisciplinas) {
            if (!sub.unidades_medida || sub.unidades_medida.length === 0) {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: `A subdisciplina ${sub.nome} está sem unidades de medida.`,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })

              return;
            }
          }
        }


        itens = itens.map(item => {
          return {
            ...item,
            subdisciplinas: item.subdisciplinas.map(sub => ({
              uuid: sub.uuid,
              quantidade: sub.quantidade,
              valor: limpaMoeda(sub.valor),
              total: limpaMoeda(sub.valor) * sub.quantidade,
              unidade_medida: sub.unidades_medida.id
            }))
          }
        })

        const proposta = {
          ...this.dadosItem,
          id: this.dadosItem.id,
          cliente: this.dadosItem.cliente?.id,
          lead: this.dadosItem.lead?.id,
          empresa: this.dadosItem.empresa?.id,
          responsavel: this.dadosItem.responsavel?.id,
          parceiro: this.dadosItem.parceiro?.id,
          status: this.dadosItem.status?.id,
          total: total,
          desconto: desconto,
          itens: itens
        }

        if (this.propostaEdicao) {
          this.update(proposta)
          return
        }
        this.create(proposta)
      },

      tratarEventoDisciplinasSelecionadas(item) {
        if (!item) {
          this.subsFiltradasModal = []
          return
        }
        this.loadModal = true
        let urlSubdisciplinas = `/subdisciplinas?disciplina=${item.id}&perPage=1000`
        axios.get(urlSubdisciplinas)
          .then((respo) => {
            this.loadModal = false

            this.subsFiltradasModal = respo.data.data.map(sub => ({
              ...sub,
              disciplina: item
            }))

            this.subsSelecionadasModal = []
          })
      },
      tratarEventoClienteSelecionado(itens) {
        this.dadosItem.cliente = itens || {}
      },
      tratarEventoResponsavelSelecionado(itens) {
        this.dadosItem.responsavel = itens || {}
      },
      tratarEventoLeadSelecionado(itens) {
        this.dadosItem.lead = itens || {}
      },
      tratarEventoParceiroSelecionado(itens) {
        this.dadosItem.parceiro = itens || {}
      },
      tratarEventoEmpresaSelecionada(itens) {
        this.dadosItem.empresa = itens || {}
      },
      tratarEventoStatusSelecionado(itens) {
        this.dadosItem.status = itens || {}
      },

      tratarDescricao(text) {
        this.dadosItem.descricao = text
      },
      updateUnidadeMedida(sub, selectedOption) {

        sub.unidade_medida = selectedOption.uuid || selectedOption.id;
      },
    },

  }
</script>
<style lang="scss" scoped>

  .modal-body {
    min-height: 300px;
    max-height: 500px;
    overflow: scroll;
  }

  .nomeItemCheck {
    font-size: 0.8rem;
  }

  .text-decoration-line-through {
    text-decoration: line-through;
  }


</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

</style>
